
import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import {   A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle'


const LogoSliderNodeView = (props) => {
    const { editor, getPos, node } = props;
    const isFullDisplay = node.attrs.layout || "default";
    const images = node.attrs.imageUrls || false;
    const imageAlts = node.attrs.imageAlt || [];
    const imageNames = node.attrs.imageName || [];
    const imageKeys = node.attrs.imageKeys || [];
    const imageIds = node.attrs.imageId || [];

    console.log({ node });
    return (
        <NodeViewWrapper data-type="logoSlider" data-layout={node.attrs.layout}>
            <div
                className={`logoslider-wapper image-width-${isFullDisplay}`}
                // style={{
                //     width: isFullDisplay === "full" ? "70vw" : "100%",
                //     position: isFullDisplay === "full" ? "relative" : "",
                //     left: isFullDisplay === "full" ? "50%" : "0",
                //     transform: isFullDisplay === "full" ? "translateX(-50%)" : "translateX(0)",
                // }}
            >
                <Swiper
                    modules={[A11y]}
                    slidesPerView={isFullDisplay === "full" ? 5 : 3}
                    spaceBetween={20}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: isFullDisplay === "full" ? 5 : 3,
                            spaceBetween: 20,
                        },
                    }}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img
                                src={image}
                                alt={imageAlts[index] || imageNames[index] || "Image"}
                                data-key={imageKeys[index]}
                                data-id={imageIds[index]}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </NodeViewWrapper>
    );
};


export const LogoSliderBlock = Node.create({
    name: 'logoSliderBlock',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            imageUrls: {
                default: '',
            },
            caption: {
                default: '',
            },
            layout: {
                default: 'default',
            },
            imageKeys: {
                default: []
            },
            imageId: {
                default: []
            },
            imageAlt: {
                default: []
            },
            imageName: {
                default: []
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
                getAttrs: (dom) => {
                    const images = Array.from(dom.querySelectorAll('img'));
                    const imageUrls = images.map(img => img.getAttribute('src'));
                    const imageKeys = images.map(img => img.getAttribute('data-key') || '');
                    const imageId = images.map(img => img.getAttribute('data-id') || '');
                    const imageAlt = images.map(img => img.getAttribute('data-alt') || '');
                    const imageName = images.map(img => img.getAttribute('data-name') || '');

                    return {
                        imageUrls: imageUrls,
                        caption: dom.getAttribute('data-caption') || '',
                        layout: dom.getAttribute('data-layout') || 'default',
                        imageKeys: imageKeys,
                        imageId: imageId,
                        imageAlt: imageAlt,
                        imageName: imageName,
                    };
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            { 'data-type': this.name, 'data-layout': HTMLAttributes.layout },
            [
                'div',
                { class: 'swiper swiper-container' },
                [
                    'div',
                    { class: 'swiper-wrapper' },
                    ...HTMLAttributes?.imageUrls?.map((item, index) => (
                        [
                            'div',
                            { class: 'swiper-slide' },
                            [
                                'img',
                                {
                                    src: item,
                                    alt: HTMLAttributes.imageAlt[index] || HTMLAttributes.imageName[index] || 'Image',
                                    'data-keys': HTMLAttributes.imageKeys[index],
                                    'data-ids': HTMLAttributes.imageId[index],
                                },
                            ],
                        ]
                    )),
                ],
            ],
        ];
    },    

    addCommands() {
        return {
            setLogoSliderBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                imageUrls: options?.imageUrls || '',
                                caption: options?.caption || '',
                                imageKeys: options?.imageKeys || '',
                                imageId: options?.imageId || '',
                                imageAlt: options?.imageAlt || '',
                                imageName: options?.imageName || '',
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(LogoSliderNodeView);
    },
});
