import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useState } from 'react';
import ReactPlayer from 'react-player/lazy'


const OtherBlockView = (props) => {
    const { editor, getPos, node } = props

    const [isVideoReady, setIsVideoReady] = useState(false);

    const handleVideoReady = () => {
        // Set loading to false once the video is ready to play
        setIsVideoReady(true);
    };

    return(
        <NodeViewWrapper data-type='other'>
            <div className='video-wrapper youtube-video video-section'>
                { !isVideoReady &&
                    <div contentEditable={false} className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
                }
                <div contentEditable={false} className={(isVideoReady) ? 'player-wrapper player-ready' : 'player-wrapper'}>
                    <ReactPlayer
                        className='react-player'
                        url={node.attrs.videourl}
                        width='100%'
                        height='100%'
                        controls
                        onReady={handleVideoReady}
                    />
                </div>
                <div className='video-title' contentEditable={true}>
                    <p className='video-caption' contentEditable={true}>{node.attrs.caption}</p>
                </div>
            </div>
        </NodeViewWrapper>
    )
}

export const OtherBlock = Node.create({
    name: 'otherBlock',
    
    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
          videourl: {
            default: '',
          },
          caption: {
            default: '',
          },
        };
    },

    parseHTML() {
        return [
          {
            tag: `div[data-type="${this.name}"]`,
          },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
          'div',
          { 'data-type': this.name },
          [
            'div',
            { class: 'youtube-content' },
            [
              'p',
              { class: 'video-caption' },
              HTMLAttributes.caption || '',
            ],
            HTMLAttributes.videourl
              ? ['iframe', { src: HTMLAttributes.imageUrl, class: 'react-player' }]
              : '',
          ],
        ];
    },

    addCommands() {
        return {
            setOtherBlock:
            (options) =>
            ({ commands }) => {
              return commands.insertContent({
                type: this.name,
                attrs: {
                    videourl: options?.videourl || '',
                    caption: options?.caption || '',
                },
              });
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(OtherBlockView);
    },
});