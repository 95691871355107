import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState } from 'react';

const SoundCloudNodeView = ({ node, editor, getPos }) => {
    const [soundCloudUrl, setSoundCloudUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setSoundCloudUrl(trimmedValue);
        setErrorMessage("");
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter' && soundCloudUrl !== '') {
            const soundCloudPattern = /^(https?:\/\/)?(soundcloud\.com|snd\.sc)\/.+$/;
            if (soundCloudPattern.test(soundCloudUrl)) {
                setIsLoading(true);

                const soundCloudSrc= `https://w.soundcloud.com/player/?url=${soundCloudUrl}&color=%231ED760&auto_play=true&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false`

                editor.chain().focus().setSoundCloudBlock({ soundCloudUrl:soundCloudSrc }).run();

                setErrorMessage("");
                setIsLoading(false);
            } else {
                setErrorMessage("Please enter a valid SoundCloud URL");
                setIsLoading(false);
            }
        }
    };

    return (
        <NodeViewWrapper>
            <div data-type="soundcloud" className="soundcloud-node-wrapper" data-drag-handle>
                <label>
                    <div className='urlInput'>
                        {
                            (isLoading )?
                                <div className={`show linkbutton-loader loader-buttonHeight`}>
                                    <div className='ring-loader'></div>
                                </div>
                                :
                                <div>
                                    <svg width='20' height='20' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(#clip0_4592_10410)'><circle cx='6.99978' cy='6.99929' r='6.96023' fill='#FF5502'/><path d='M10.1989 9.22798H6.85002V5.22313C7.16031 4.94265 7.56218 4.77344 8.00119 4.77344C8.97269 4.77344 9.76232 5.60208 9.77996 6.63141C9.91098 6.58209 10.052 6.55526 10.1989 6.55526C10.8924 6.55526 11.4547 7.15356 11.4547 7.89162C11.4547 8.62968 10.8924 9.22798 10.1989 9.22798Z' fill='white'/><path d='M6.54559 5.58335H6.1365V9.22798H6.54559V5.58335Z' fill='white'/><path d='M5.31832 5.98831H5.72741V9.22798H5.31832V5.98831Z' fill='white'/><path d='M4.90923 6.79823H4.50013V9.22798H4.90923V6.79823Z' fill='white'/><path d='M3.68195 6.39327H4.09104V9.22798H3.68195V6.39327Z' fill='white'/><path d='M3.27286 7.20319H2.86377V9.22798H3.27286V7.20319Z' fill='white'/></g><defs><clipPath id='clip0_4592_10410'><rect width='14' height='14' fill='white'/></clipPath></defs></svg>
                                    <input
                                        className="urlInputLeft"
                                        type="text"
                                        placeholder="Paste SoundCloud URL to add embedded content"
                                        value={soundCloudUrl}
                                        onChange={handleInputChange}
                                        onKeyPress={handleKeyPress}
                                    />
                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                </div>
                        }
                    </div>
                </label>
            </div>
        </NodeViewWrapper>
    );
};

export default SoundCloudNodeView;

export const SoundCloudEmbeds = Node.create({
    name: 'soundcloud',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,
    selectable: true,
    inline: false,

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML() {
        return ['div', { 'data-type': this.name }]
    },

    addCommands() {
        return {
            setSoundCloudEmbeds: () => ({ commands }) =>
                commands.insertContent(`<div data-type="${this.name}"></div>`),
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(SoundCloudNodeView);
    },
});
