
import { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Select, { components } from "react-select";
import ReactFlagsSelect from "react-flags-select";

import { timezoneData } from "../../Components/Component";
import Loader from "../../Components/Loader/Loader";
import ImageEditor from "../../Components/ImageEditor/ImageEditor";
import UploadMediaPopup from "../../Components/Modal/UploadMediaPopup";
import DeleteAccountModal from "../../Components/DeleteAccountModal";

import { dataURLtoFile } from "../../utils/functions";

import avatar from "../../images/profile-user.svg";
import avatarProfile from "../../images/cover_image.svg";
import coverImg from "../../images/picture.svg";
import selectDown from "../../images/Settings/select_down.svg";
import featured from "../.././images/featured.svg";
import deleteImage from '../../../src/images/delete.svg';
import TheamLight from "../../../src/images/White.png"
import TheamDark from "../../../src/images/Dark.png"

import "../.././App.css";
import NotificationMessage from "../../Components/NotificationMessage";

function General(props) {
  const fileInputRef = useRef(null);
  const profileFileInputRef = useRef(null);
  const ref = useRef();
  const modalRef = useRef(null);
  const profileModalRef = useRef(null);

  const location = useLocation();

  const [featureImage, setFeatureImage] = useState(null);
  const [saveFeatureImage, setSaveFeatureImage] = useState(null);
  const [oldImage, setOldImage] = useState(null);
  const [loadingFeatureImg, setloadingFeatureImg] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [profileIsDragging, setProfileIsDragging] = useState(false);
  const [featuredImageModal, setFeaturedImageModal] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [oldZoom, setOldZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [replcaeImageZoom, setReplcaeImageZoom] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedCoverImageFile, setUploadedCoverImageFile] = useState(null);
  const [newUploadedImage, setNewUploadedImage] = useState(null);
  const [showUploadMediaPopup, setShowUploadMediaPopup] = useState(false);
  const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
  const [uploadMediaPopupTab, setUploadMediaPopupTab] = useState('upload');

  const [newProfileUploadedImage, setNewProfileUploadedImage] = useState(null);
  const [profileCoverModal, setProfileCoverModal] = useState(false);
  const [loadingProfileImg, setloadingProfileImg] = useState(false);
  const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
  const [uploadedProfileImageFile, setUploadedProfileImageFile] = useState(null);
  const [saveProfileImage, setSaveProfileImage] = useState(null);
  const [oldProfileImage, setOldProfileImage] = useState(null);
  const [profileZoom, setProfileZoom] = useState(1);
  const [oldProfileZoom, setOldProfileZoom] = useState(1);
  const [profilePosition, setProfilePosition] = useState({ x: 0.5, y: 0.5 });
  const [replcaeProfileImageZoom, setReplcaeProfileImageZoom] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [slugErrorMessage, setSlugErrorMessage] = useState('')
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const emailParams = decodeURIComponent(searchParams.get("email"));

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [countryCode, setSelectedCountry] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState(null);
  const [coverPictureDragging, setCoverPictureDragging] = useState(false);
  const [profileCropedImageId, setProfileCropedImageId] = useState('');
  const [profileImageId, setProfileImageId] = useState('');
  const [coverCropedImageId, setCoverCropedImageId] = useState('');
  const [coverImageId, setCoverImageId] = useState('');

  const [selectedProfileImage, setSelectedProfileImage] = useState('');
  const [selectMediaLoading, setSelectMediaLoading] = useState(false);
  const [selectedProfileImageData, setSelectedProfileImageData] = useState('');
  const [selectedCoverImageData, setSelectedCoverImageData] = useState('');
  
  const [countrySelectOpen, setCountrySelectOpen] = useState(false);
  const [activeTimezone, setActiveTimezone] = useState("");

  const [theme, setTheme] = useState("dark")
  const [stripeData,setStripeData] = useState("")
  const [notification, setNotification] = useState(false);
  const [subscriberCount, setSubscriberCount]= useState(0)
  const [subscriberNotify, setSubscriberNotify] = useState(false);


  const options = Object.entries(timezoneData).map((region) => ({
    label: region[0],
    options: region[1].map((timezone) => ({
      value: timezone,
      label: timezone,
    })),
  }));

  props.setActivePage("General");

  useEffect(() => {
    if (token) {
        const tokenVerify = () => {
           axios.put( `${process.env.REACT_APP_API_URL}/admin/emailverifytoken?token=${token}&email=${emailParams}` )
            .then((response) => {
                if( response.status == 200 ) {
                  setEmail(response.data.user.email);
                }
            })
            .catch((err) => {
                console.log(err.response)
            });
        };
        tokenVerify();
    }
 }, []);


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        (!modalRef.current.contains(event.target) &&
          featuredImageModal === false) ||
        event.target.className === "overlayModal"
      ) {
        setFeaturedImageModal(false);
        setZoom(oldZoom);
        setOldImage(newUploadedImage);
      }
    };

    if (featuredImageModal) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [featuredImageModal]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if ( (!profileModalRef.current.contains(event.target) && profileCoverModal === false) || event.target.className === "profileOverlayModal" ) {
        setProfileCoverModal(false);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.remove("profile-uploaded");
        setProfileZoom(oldProfileZoom);
        setOldProfileImage(newProfileUploadedImage);
      }
    };

    if (profileCoverModal) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [profileCoverModal]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (countrySelectOpen && ref.current && !ref.current.contains(e.target)) {
        setCountrySelectOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [countrySelectOpen]);

  useEffect(() => {
    if(selectedProfileImage !== '') {
      setBlobData();
    }
    
  }, [selectedProfileImage]);

  const setBlobData = async () => {
    setSelectMediaLoading(true);

    if(selectedProfileImage?.url) {
      try {  
        const imageData = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/fetchImageData/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
          selectedProfileImage,
          { responseType: 'blob' }
        );
        const imageUrl = URL.createObjectURL(imageData?.data);
  
        if( uploadMediaPopupFor === 'profile-picture' ) {
          // setOldProfileZoom(1);
          setProfileZoom(1);
          setOldProfileImage(imageUrl);
          if( image === null ) {            
            setImage(imageUrl);
          }

          setUploadedProfileImage(imageUrl)
          setUploadedProfileImageFile(null)
    
          setloadingProfileImg(false);
          setProfileCoverModal(true);
          setReplcaeProfileImageZoom(false);
          setSelectedProfileImageData(selectedProfileImage);
        }

        if( uploadMediaPopupFor === 'cover-picture' ) {
          // setOldZoom(1);
          setZoom(1);

          setUploadedImage(imageUrl);
          setUploadedCoverImageFile(null);
          setOldImage(imageUrl);

          if(featureImage === null) {
            setFeatureImage(imageUrl);
          }

          setloadingFeatureImg(false);
          setFeaturedImageModal(true);
          setReplcaeImageZoom(false);
          setSelectedCoverImageData(selectedProfileImage);
        }
        
        
        setSelectMediaLoading(false);
        setShowUploadMediaPopup(false);
        setUploadMediaPopupFor('');
      } catch (error) {
        setSelectMediaLoading(false);
        console.log({error:error, message: error.message})
      }
    }
    setSelectMediaLoading(false);
  }

  function clearAll() {
    setCancelLoading(true);
    fetchData();
  }

  function handleDragOver(e) {
    e.preventDefault();
    if (!profileIsDragging) {
      setProfileIsDragging(true);
    }
  }

  const handleProfileDragLeave = (e) => {
    e.preventDefault();
    setProfileIsDragging(false);
  };

  function handleDrop(e) {
    var divElement = document.getElementById("profile-picturebox");
    divElement.classList.remove("profile-uploaded");
    setloadingProfileImg(true);
    var divElement = document.getElementById("profile-picturebox");
    divElement.classList.add("profile-border");
    var TempReplcaeProfileImageZoom = replcaeProfileImageZoom;

    if (image) {
      setReplcaeProfileImageZoom(true);

      TempReplcaeProfileImageZoom = true;
    }

    if (TempReplcaeProfileImageZoom === true) {
      setOldProfileZoom(1);
      setProfileZoom(1);
    } else {
      setProfileZoom(oldProfileZoom);
    }

    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      TempReplcaeProfileImageZoom !== true && setImage(reader.result);
      setOldProfileImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setUploadedProfileImage(null);
      setUploadedProfileImageFile(null)
      setTimeout(() => {
        reader.readAsDataURL(file);
        setloadingProfileImg(false);
        setUploadedProfileImage(file);
        setUploadedProfileImageFile(file);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-uploaded");
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.remove("profile-border");
        setProfileCoverModal(true);
        setReplcaeProfileImageZoom(false);
      }, 4000);
    } else {
      setloadingProfileImg(false);
    }
    setProfileIsDragging(false);
  }

  function handleFileUpload(e) {
    setloadingProfileImg(true);
    var divElement = document.getElementById("profile-picturebox");
    divElement.classList.add("profile-border");
    if (replcaeProfileImageZoom === true) {
      setOldProfileZoom(1);
      setProfileZoom(1);
    } else {
      setProfileZoom(oldProfileZoom);
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      replcaeProfileImageZoom !== true && setImage(reader.result);
      setOldProfileImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setUploadedProfileImage(null);
      setUploadedProfileImageFile(null);
      setTimeout(() => {
        reader.readAsDataURL(file);
        setloadingProfileImg(false);
        setUploadedProfileImage(file);
        setUploadedProfileImageFile(file);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-uploaded");
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.remove("profile-border");
        setProfileCoverModal(true);
        setReplcaeProfileImageZoom(false);
      }, 4000);
    } else {
      setloadingProfileImg(false);
    }
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={selectDown} alt="select-down" />
      </components.DropdownIndicator>
    );
  };
  const handleTimezoneChange = (selectedOption) => {
    setActiveTimezone(selectedOption.value);
  }
  
  const colourStyles = {
    option: (styles, { isFocused, isSelected, isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? "#007AFF"
          : isFocused
            ? "#f9f9f9"
            : "transparent",
        fontSize: "14px",
        cursor: "pointer",
      };
    },
    input: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: 0,
      margin: 0,
      borderRadius: "8px",
      fontSize: "14px",
      cursor: "pointer",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: "#101828",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      width: "100%",
    }),
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none !important",
      borderRadius: "8px",
      marginRTop: "2px",
    }),
    container: (provided, state) => ({
      ...provided,
      paddingLeft: "0px",
    }),
    placeholder: (styles, { isFocused }) => {
      return {
        ...styles,
        marginBottom: isFocused ? "0px" : "0px",
        fontSize: "14px",
        marginLeft: "0",
        color: "#667085",
      };
    },
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      outline: "none",
      marginRight: "5px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: "15px",
      paddingRight: "15px",
    }),
  };

  const handlefeatureImageDragOver = (e) => {
    e.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleClick = () => {
    setFeaturedImageModal(!featuredImageModal);
    var divElement = document.getElementById("newpageImageUpload");
    divElement.classList.add("imageUploaded");
  };
  const handleProfileClick = () => {
    setProfileCoverModal(!profileCoverModal);
  };

  const handlefeatureImageDrop = (e) => {
    var divElement = document.getElementById("newpageImageUpload");

    divElement.style.height = divElement.clientHeight + "px";

    divElement.classList.remove("imageUploaded");
    setloadingFeatureImg(true);

    var TempReplcaeImageZoom = replcaeImageZoom;

    if (featureImage) {
      setReplcaeImageZoom(true);
      TempReplcaeImageZoom = true;
    }

    if (TempReplcaeImageZoom === true) {
      setOldZoom(1);
      setZoom(1);
    } else {
      setZoom(oldZoom);
    }
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        if (file) {
          setUploadedImage(file);
          setUploadedCoverImageFile(file);
          TempReplcaeImageZoom !== true && setFeatureImage(reader.result);
          setOldImage(reader.result);
        }
      };
    };
    if (file) {
      setTimeout(() => {
        reader.readAsDataURL(file);
        setCoverPictureDragging(false);
        setUploadedImage(file);
        setUploadedCoverImageFile(file);
        setloadingFeatureImg(false);
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
        divElement.classList.add("imageUploaded-wrap");
        setFeaturedImageModal(true);
        setOldImage(file);
        setReplcaeImageZoom(false);
        divElement.style.height = "auto";
      }, 4000);
    } else {
      setloadingFeatureImg(false);
      divElement.style.height = "auto";
    }
    setIsDragging(false);
  };

  const handlefeatureImageFileUpload = (e) => {
    var divElement = document.getElementById("newpageImageUpload");
    divElement.style.height = divElement.clientHeight + "px";
    divElement.classList.remove("imageUploaded");
    setloadingFeatureImg(true);
    if (replcaeImageZoom === true) {
      setOldZoom(1);
      setZoom(1);
    } else {
      setZoom(oldZoom);
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        replcaeImageZoom !== true && setFeatureImage(reader.result);
        setOldImage(reader.result);
      };
    };
    if (file) {
      reader.readAsDataURL(file);
      setUploadedImage(null);
      setUploadedCoverImageFile(null);

      setTimeout(() => {
        reader.readAsDataURL(file);
        setCoverPictureDragging(false);
        setloadingFeatureImg(false);
        setUploadedImage(file);
        setUploadedCoverImageFile(file);
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
        divElement.classList.add("imageUploaded-wrap");
        setFeaturedImageModal(true);
        setReplcaeImageZoom(false);
        divElement.style.height = "auto";
      }, 4000);
    } else {
      setloadingFeatureImg(false);
      divElement.style.height = "auto";
    }
  };

  const handleImageLoad = () => {
    const img = new Image();
    img.src = featureImage;
    img.onload = () => { };
  };
  const handleProfileImageLoad = () => {
    const img = new Image();
    img.src = image;
    img.onload = () => { };
  };

  const handleReplaceImg = () => {
    setShowUploadMediaPopup(true);
    setUploadMediaPopupFor('cover-picture');
    setProfileCoverModal(false);
    setFeaturedImageModal(false)
    setReplcaeImageZoom(true);
  };
  const handleReplaceProfileImg = () => {
    setShowUploadMediaPopup(true);
    setUploadMediaPopupFor('profile-picture');
    setProfileCoverModal(false)
    setReplcaeProfileImageZoom(true);
  };

  useEffect(() => {
    setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    setProfileZoom(profileZoom);
  }, [profileZoom]);

  const handleZoom = (zoom) => {
    setZoom(zoom);
  };
  const handleProfileZoom = (profileZoom) => {
    setProfileZoom(profileZoom);
  };

  const handlePosition = (newPosition) => {
    setPosition(newPosition);
  };
  const handleProfilePosition = (newProfilePosition) => {
    setProfilePosition(newProfilePosition);
  };

  const handleSavePicture = async () => {
    let click = document.getElementById("clickFunction");
    click.click();
    setFeatureImage(featureImage);
    setNewUploadedImage(oldImage);
    setSaveFeatureImage(featureImage);
    setFeaturedImageModal(false);
    setOldZoom(zoom);
  };
  const handleSaveProfilePicture = async () => {
    let click = document.getElementById("clickFunction");
    click.click();
    setImage(image);
    setNewProfileUploadedImage(oldProfileImage);
    setSaveProfileImage(image);
    setProfileCoverModal(false);
    var divElement = document.getElementById("profile-picturebox");
    divElement.classList.add("profile-uploaded");
    setOldProfileZoom(profileZoom);
  };

  const handleMouseDown = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.add("slider-cursor");
  };

  const handleMouseUp = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.remove("slider-cursor");
  };

  const handleTouchStart = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.add("slider-cursor");
  };

  const handleTouchEnd = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.remove("slider-cursor");
  };

  const handleCancel = () => {
    if (!saveFeatureImage) {
      var divElement = document.getElementById("newpageImageUpload");
      featureImage && divElement.classList.remove("imageUploaded");
    } else {
      var divElement = document.getElementById("newpageImageUpload");
      featureImage && divElement.classList.add("imageUploaded");
    }
    setOldImage(newUploadedImage);
    setFeaturedImageModal(false);
    setZoom(oldZoom);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      setUploadedImage(null);
      setUploadedCoverImageFile(null);
    }
  };

  const handleProfileCancel = () => {
    if (saveProfileImage !== null) {
      var divElement = document.getElementById("profile-picturebox");
      divElement.classList.add("profile-uploaded");
    } else {
      var divElement = document.getElementById("profile-picturebox");
      divElement.classList.remove("profile-uploaded");
    }
    setOldProfileImage(newProfileUploadedImage);
    setProfileCoverModal(false);
    setProfileZoom(oldProfileZoom);

    if (profileFileInputRef.current) {
      profileFileInputRef.current.value = null;
      setUploadedProfileImage(null);
      setUploadedProfileImageFile(null);
    }
  };

  const toConvertUppercase = (string) => {
    const words = string.split(" ");

    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const capitalizedString = capitalizedWords.join(" ");
    return capitalizedString;
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpenDeleteAccount, setIsPopupOpenDeleteAccount] = useState(false);

  useEffect(() => {
    if (isPopupOpen === true || isPopupOpenDeleteAccount === true) {
      document.body.classList.add("hidden-message");
    } else {
      document.body.classList.remove("hidden-message");
    }

    return () => {
      document.body.classList.remove("hidden-message");
    };
  }, [isPopupOpen, isPopupOpenDeleteAccount]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target != null && isPopupOpenDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
        setIsPopupOpenDeleteAccount(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isPopupOpenDeleteAccount]);


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target != null && isPopupOpen && (e.target.classList.contains('welcomeOverlay'))) {
        setIsPopupOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isPopupOpen]);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
      );
      const userData = response?.data?.result?.user;
      const userMeta = response?.data?.result?.userMeta;
      const userMediaData = response?.data?.result?.userMediaData;
      setSubscriberCount(response?.data?.subscriberCount)
      
      setStripeData(userMeta?.stripeAccountData)
      const firstName = toConvertUppercase(userData?.firstName);
      const lastName = toConvertUppercase(userData?.lastName);
      if(response?.data?.subscriberCount > 0){
        setSubscriberNotify(true)
      }
      if (userData) {
        setFirstName(firstName);
        setLastName(lastName);
        setEmail(userData.email);
      }
      
      if (userMeta) {
        setJobTitle(userMeta.job_title);
        setDisplayName(userMeta.displayName);
        setProfileUrl(userMeta.profileSlug);
        setSelectedCountry(userMeta.country);
        setTheme(userMeta?.theme || 'dark');
        const userTimezone = userMeta.timeZone;
        let formattedTimezone = userTimezone;
        for (const region in timezoneData) {
          const foundZone = timezoneData[region].find(zone => zone.includes(userTimezone));
          if (foundZone) {
            formattedTimezone = foundZone;
            break;
          }
        }
        setActiveTimezone(`${formattedTimezone}`);

        if(userMeta?.profileMedia?.cropedImage) {
          const imageData = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/fetchImageData/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            userMediaData.profileMeida.cropedMedia,
            { responseType: 'blob' }
          );

          const imageUrl = URL.createObjectURL(imageData.data);

          setImage(imageUrl);
          setOldProfileImage(imageUrl);
          setProfileCropedImageId(userMeta?.profileMedia?.cropedImage)
        }

        if(userMeta?.profileMedia?.selectedImage) {
          const imageData = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/fetchImageData/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            userMediaData.profileMeida.selectedMedia,
            { responseType: 'blob' }
          );

          const imageUrl = URL.createObjectURL(imageData.data);
          // setImage(imageUrl);
          setOldProfileImage(imageUrl);
          setNewProfileUploadedImage(imageUrl);
          setSaveProfileImage(imageUrl);
          setProfileImageId(userMeta.profileMedia.selectedImage);
        }

        if(userMeta?.profileMedia?.profileZoom) {
          setProfileZoom(userMeta.profileMedia.profileZoom)
          setOldProfileZoom(userMeta.profileMedia.profileZoom)
        }

        if(userMeta?.profileMedia?.profilePosition) {
          setProfilePosition(userMeta.profileMedia.profilePosition)
        }

        if(userMeta?.coverMedia?.cropedImage) {
          const imageData = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/fetchImageData/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            userMediaData.coverMedia.cropedMedia,
            { responseType: 'blob' }
          );

          const imageUrl = URL.createObjectURL(imageData.data);

          setFeatureImage(imageUrl);
          setSaveFeatureImage(imageUrl);
          setCoverCropedImageId(userMeta.coverMedia.cropedImage);          
        }

        if(userMeta?.coverMedia?.selectedImage) {
          const imageData = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/fetchImageData/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            userMediaData.coverMedia.selectedMedia,
            { responseType: 'blob' }
          );

          const imageUrl = URL.createObjectURL(imageData.data);

          setUploadedImage(imageUrl);
          setUploadedCoverImageFile(null);
          setNewUploadedImage(imageUrl);
          setOldImage(imageUrl);

          setCoverImageId(userMeta.coverMedia.selectedImage);
        }

        if(userMeta?.coverMedia?.profileZoom) {
          setZoom(userMeta.coverMedia.profileZoom)
          setOldZoom(userMeta.coverMedia.profileZoom)
        }

        if(userMeta?.coverMedia?.profilePosition) {
          setPosition(userMeta.coverMedia.profilePosition)
        }

      }
      setCancelLoading(false);
    } catch (error) {
      setCancelLoading(false);
      console.log(error, "error");
    }
  };

  const updateData = async () => {
    try {
      setIsLoading(true);

      let profileCropedImage = profileCropedImageId;
      let profileImage = profileImageId;
      let coverCropedImage = coverCropedImageId;
      let coverImage = coverImageId;

      const featureImageData = await getFeatureImageData()

      profileCropedImage = featureImageData?.profileCropedImage || profileCropedImage;
      profileImage = featureImageData?.profileImage || profileImage;
      coverCropedImage = featureImageData?.coverCropedImage || coverCropedImage;
      coverImage = featureImageData?.coverImage || coverImage;
          
      const updateData = {
        firstName: firstName,
        lastName: lastName,
        displayName: displayName,
        job_title: jobTitle,
        email: email,
        profileSlug: profileUrl,
        timeZone: activeTimezone,
        country: countryCode,
        theme: theme,
        profileMedia: {
          selectedImage: profileImage,
          cropedImage: profileCropedImage,
          profilePosition: profilePosition,
          profileZoom: profileZoom
        },
        coverMedia: {
          selectedImage: coverImage,
          cropedImage: coverCropedImage,
          profilePosition: position,
          profileZoom: zoom
        }
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
        updateData
      );
      
      setIsLoading(false);
      setNotification(true)
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        if ( error.response.status === 406 ) {
          setEmailErrorMessage('Email is already assigned to another user.');
        } 
        if( error.response.status === 409 )  {
          setSlugErrorMessage('Profile URL already exists.')
        }
      }
      console.log({error:error, message:error.message});
    }
  };

  const openDeleteAccountPopup = () => {
    setIsPopupOpenDeleteAccount(true)
  }

  const closeAccountDeletedPopup = () => {
    document.body.classList.remove('hidden-message');
    setIsPopupOpenDeleteAccount(false)
  }

  const getFeatureImageData = async () => {
    let profileCropedImage = profileCropedImageId;
    let profileImage = profileImageId;
    let coverCropedImage = coverCropedImageId;
    let coverImage = coverImageId;

    if (uploadedProfileImageFile !== null) {
      const imageFormData = new FormData();
		  imageFormData.append('files', uploadedProfileImageFile);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
          imageFormData, {
          headers: {
          'Content-Type': 'multipart/form-data'
          }
        });

        profileImage = res.data.result[0]._id;

        const profileImageFile = await dataURLtoFile(image, "profile-picture.jpg");
        const formData = new FormData();
        formData.append('files', profileImageFile);

        const uploadedMedia = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
          formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        );

        profileCropedImage = uploadedMedia?.data?.result[0]?._id || profileCropedImage;

      } catch (error) {
              console.log(error);
          }
    } else if ((profileImageId !== '' || selectedProfileImageData?._id !== "") && oldProfileImage !== saveProfileImage) {
      const profileImageFile = await dataURLtoFile(image, "profile-picture.jpg");

      const formData = new FormData();
      formData.append('files', profileImageFile);

      const uploadedMedia = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
        formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
      );

      profileCropedImage = uploadedMedia?.data?.result[0]?._id || profileCropedImage;
      profileImage = selectedProfileImageData?._id || profileImage;
    }

    if (uploadedCoverImageFile !== null) {
      const imageFormData = new FormData();
		  imageFormData.append('files', uploadedCoverImageFile); // Append each file individually

      try {

        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
          imageFormData, {
          headers: {
          'Content-Type': 'multipart/form-data'
          }
        });
        coverCropedImage = res.data.result[0]._id;

        const coverImageFile = await dataURLtoFile(featureImage, "cover-picture.jpg");

        const formData = new FormData();
        formData.append('files', coverImageFile);

        const uploadedMedia = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
          formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        );

        coverCropedImage = uploadedMedia?.data?.result[0]?._id || coverCropedImage;

      } catch (error) {
        console.log(error);
        }
    } else if((coverCropedImageId !== '' || selectedCoverImageData?._id) && oldImage !== saveFeatureImage ) {
      const coverImageFile = await dataURLtoFile(featureImage, "cover-picture.jpg");

      const formData = new FormData();
      formData.append('files', coverImageFile);

      const uploadedMedia = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
        formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
      );

      coverCropedImage = uploadedMedia?.data?.result[0]?._id || coverCropedImage;
      coverImage = selectedCoverImageData?._id || coverImage;
    }
    
    const result = { profileCropedImage, profileImage, coverCropedImage, coverImage }

		return result;
  }


  const [messages, setMessages] = useState("");
    const [subscriberAddedNotify, setSubscriberAddedNotify] = useState(false);
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8080');
    
        webSocket.onopen = () => {
        //   console.log('Connected to WebSocket server');

        };
    
        webSocket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData?.author_id === user_id){
                if(parsedData?.message !== "" && parsedData?.message !== undefined){
                    setSubscriberAddedNotify(true);
                    setMessages(parsedData?.message);
                }
            }
        };
    
        return () => {
            webSocket.close();
        };
    }, []);
  return (
    <div className="general">
      <div className="generalTop">
        <div className="generalTopLeft">
          <p className="generalTopLeftTitle">General</p>
          <p className="generalTopLeftDesc">
            Update your company photo and details here.
          </p>
        </div>
      </div>

      <div className="generalRow">
        <div className="generalRowDesc">
          <p>Basic info</p>
          <p>This will be displayed on your profile.</p>
        </div>

        <div className="generalBlockItems">
          <div className="deneralBlockInputs">
            <div>
              <p className="field-label">First Name</p>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <p className="field-label">Last Name</p>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div>
            <p className="field-label">Display Name</p>
            <input
              type="text"
              className="displayName"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </div>
          <div>
            <p className="field-label">Job title</p>
            <input
              type="text"
              className="jobTitle"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>
          <div>
            <p className="field-label">Profile URL</p>
            <div className="profileUrl">
              <div>x.com/</div>
              <input
                type="text"
                value={profileUrl}
                onChange={(e) => setProfileUrl(e.target.value)}
              />
            </div>
            { slugErrorMessage &&
              <div className="error-message">
                  {slugErrorMessage}
              </div>
            }
          </div>
          <div>
            <p className="field-label">Country or Region</p>
            <ReactFlagsSelect
              selected={countryCode}
              searchable
              searchPlaceholder="Search countries"
              onSelect={(code) => setSelectedCountry(code)}
              className='countryDropdown'
            />
          </div>
        </div>
      </div>

      <div className="generalRow">
        <div className="generalRowDesc">
          <p className="field-label">Email address</p>
          <p>This will be displayed on your profile.</p>
        </div>

        <div className="generalBlockItems">
          <p className="field-label">Email</p>
          <div className="emailAdressBlock">
            <input
              type="text"
              className="emailAdress"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          { emailErrorMessage &&
              <div className="error-message">
                  {emailErrorMessage}
              </div>
          }
        </div>
      </div>

      <div className="generalRow">
        <div className="generalRowDesc">
          <p className="field-label">Timezone</p>
          <p>This will be displayed on your profile.</p>
        </div>

        <div className="generalBlockItems timezoneinput">
          <div className="deneralBlockInputs blocksSelects blockTimezone">
            <div>
              <p className="field-label">Timezone</p>
              <Select
                className="timezome-selection"
                value={{ value: activeTimezone, label: activeTimezone }}
                onChange={handleTimezoneChange}
                options={options}
                styles={colourStyles}
                components={{ DropdownIndicator }}
              />

              <div className="stepCheckbox_block">
                <input className="stepCheckbox" type="checkbox" id="startDay" />
                <label htmlFor="startDay">Start week on Monday</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="generalRow rowChangeImage">
        <div className="generalRowDesc">
          <p>Profile Picture</p>
          <p>Click on image for change profile picture.</p>
        </div>
        <div className="generalBlockItems">
          <div className="avatarBlock" onDragOver={handleDragOver} onDragLeave={handleProfileDragLeave} onDrop={handleDrop} >
            <div className="avatarUpload">
              {saveProfileImage ? (
                <div id="profile-picturebox" className="registerProfile-block" onClick={handleProfileClick} >
                  {loadingProfileImg === true && profileCoverModal === false ? (
                    <div id="profileImg-loader" className="registerProfileImg-loader" >
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <div onClick={handleProfileClick}>
                        <img src={image} className="newpage_ProfileImg" alt="Uploaded" onLoad={handleProfileImageLoad} />
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div id="profile-picturebox" className="registerProfile-block" onClick={() => {setShowUploadMediaPopup(true); setUploadMediaPopupFor('profile-picture');}} >
                  {loadingProfileImg === true && profileCoverModal === false ? (
                    <div id="profileImg-loader" className="registerProfileImg-loader" >
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <img className="avatarUpload-img" src={avatar} alt="avatar-Images" />
                    </>
                  )}
                </div>
              )}
              <input type="file" ref={profileFileInputRef} id="upload-img" onChange={handleFileUpload} accept="image/*" hidden />
            </div>
            <div
              className="newPageRightRow"
              onClick={() => {
                if (saveProfileImage) {
                  handleProfileClick();
                } else {
                  setShowUploadMediaPopup(true);
                  setUploadMediaPopupFor('profile-picture');
                }
              }}
            >
              <div className="fileUploadProfile-box">
                <p className="newPageImageUploadText">
                  <span className="newPageImageText">Profile Picture</span> <br />
                  Min 400x400px, PNG or JPEG <br />
                  <span className="btnUpload">
                    {saveProfileImage ? 'Replace' : 'Upload'}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="generalRow rowChangeCover">
        <div className="generalRowDesc">
          <p>Cover picture</p>
          <p>Click on image for change cover picture.</p>
        </div>

        <div className="generalBlockItems">
          <div
            className="newPageRightRow"
            onDragOver={handlefeatureImageDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handlefeatureImageDrop}
          >
            {saveFeatureImage ? (
              <div id="newpageImageUpload" className={ isDragging ? "draging newpageImageUpload generalImage" : "newpageImageUpload generalImage" } onClick={handleClick} >
                {loadingFeatureImg === true && featuredImageModal === false ? (
                  <div className="featureImg-loader">
                    <Loader />
                  </div>
                ) : (
                  <div className="cover_image">
                    <div onClick={handleClick}>
                      <img className="newpage_CoverImg" src={featureImage} onLoad={handleImageLoad} alt="Featured-Images" />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                id="newpageImageUpload"
                className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" }
                onClick={() => {setShowUploadMediaPopup(true); setUploadMediaPopupFor('cover-picture'); setUploadMediaPopupTab('upload');}}
              >
                {loadingFeatureImg === true && featuredImageModal === false ? (
                  // {(loadingFeatureImg === true && replaceImgTimeLoading === false) ? (
                  <div className="featureImg-loader">
                    <Loader />
                  </div>
                ) : (
                  <div className="featureImgMiddleContent">
                    <img className="fileUploadCover_Img" src={featured} alt="Featured-Images" />
                    <p className="newPageImageUploadText">                    
                      <span>Upload</span> or <span onClick={(e) => {e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('cover-picture'); setUploadMediaPopupTab('media');}}>Open Media Library</span>
                      <br />
                      JPG, PNG (max. 128MB)
                    </p>
                  </div>
                )}
              </div>
            )}
            <input
              type="file"
              ref={fileInputRef}
              id="feature-img"
              onChange={handlefeatureImageFileUpload}
              accept="image/*"
              hidden
            />
          </div>
        </div>
      </div>

      <div className="generalRow rowChangeTheme">
        <div className="generalRowDesc">
          <p>Theme style</p>
          <p>Choose between light or dark.</p>
        </div>

        <div className="generalBlockItems themeLayout">
          <div className="theme">
            <div className={`theamLight ${theme === 'light' && 'active'}`}>
              <img src={featureImage} alt='' className="coverimg coverimg-light" />
              <img src={TheamLight} alt="TheamLight" onClick={() => setTheme('light')} className="themetype" />
            </div>

            <div className={`theamDark ${theme === 'dark' && 'active'}`}>
              <img src={featureImage} alt='' className="coverimg coverimg-dark" />
              <img src={TheamDark} alt="TheamDark" onClick={() => setTheme('dark')} className="themetype" />
            </div>

          </div>
        </div>
      </div>

      <div className="generalBottom">
        <button className='account-button' onClick={openDeleteAccountPopup}>
          <img src={deleteImage} alt="delete-icon" />
          Delete account
        </button>
        {isPopupOpenDeleteAccount && <div className="welcomeOverlay"></div>}
        <DeleteAccountModal isOpen={isPopupOpenDeleteAccount} onClose={closeAccountDeletedPopup} stripeData={stripeData}/>
      </div>
      
      
      <div className="saveButtons">
        <button className={`settingsWhite ${cancelLoading ? 'btn_loading' : ''}`} onClick={(e) => clearAll()}>
          {cancelLoading ?
            <div className="settingPage-cancelLoader ">
              <div className="ring-loader cancel"></div>
            </div>
            : "Cancel"}
        </button>
        <button className={`settingsBlue ${isLoading ? 'btn_loading' : ''}`} onClick={updateData}>
          {isLoading ?
            <div className="settingPage-loader">
              <div className="ring-loader"></div>
            </div>
            : "Save"}
        </button>
      </div>

      {featuredImageModal && (
        <>
          <div className="imagCropModal">
            <div className="featuredImageModal featurboxModal" ref={modalRef}>
              <div className="modal-content">
                {uploadedImage === null ? (
                  <div
                    className="newPageRightRow"
                    onDragOver={handlefeatureImageDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handlefeatureImageDrop}
                  >
                    {((saveFeatureImage && saveFeatureImage === oldImage) ||
                      saveFeatureImage !== featureImage) &&
                      (saveFeatureImage ? (
                        <div
                          id="newpageImageUpload"
                          className={
                            isDragging
                              ? "draging newpageImageUpload"
                              : "newpageImageUpload"
                          }
                        >
                          {loadingFeatureImg === true ? (
                            <div className="featureImg-loader">
                              <Loader />
                            </div>
                          ) : (
                            <>
                              <div>
                                <div className="crop-container">
                                  <ImageEditor
                                    type="featureImg"
                                    image={featureImage}
                                    oldImage={oldImage}
                                    setOldImage={setOldImage}
                                    setFeatureImage={setFeatureImage}
                                    setUploadedImage={setUploadedImage}
                                    replaceImage={handlefeatureImageFileUpload}
                                    zoom={zoom}
                                    position={position}
                                    setZoom={handleZoom}
                                    setPosition={handlePosition}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div
                          id="newpageImageUpload"
                          className={
                            isDragging
                              ? "draging newpageImageUpload"
                              : "newpageImageUpload"
                          }
                          onClick={() =>
                            document.getElementById("feature-img").click()
                          }
                        >
                          {loadingFeatureImg === true ? (
                            <div className="featureImg-loader">
                              <Loader />
                            </div>
                          ) : (
                            <div className="featureImgMiddleContent">
                              <img className="profile-cover" src={avatar} alt="avatar-Images" />
                              <p className="uploadimgs-text">
                                Drag and drop an image or click to upload
                              </p>
                            </div>
                          )}
                        </div>
                      ))}

                    {featureImage &&
                      saveFeatureImage === featureImage &&
                      (featureImage ? (
                        <div
                          id="newpageImageUpload"
                          className={
                            isDragging
                              ? "draging newpageImageUpload"
                              : "newpageImageUpload"
                          }
                        >
                          {loadingFeatureImg === true ? (
                            <div className="featureImg-loader">
                              <Loader />
                            </div>
                          ) : (
                            <>
                              <div>
                                <div className="crop-container">
                                  <ImageEditor
                                    type="featureImg"
                                    image={featureImage}
                                    oldImage={oldImage}
                                    setOldImage={setOldImage}
                                    setFeatureImage={setFeatureImage}
                                    setUploadedImage={setUploadedImage}
                                    replaceImage={handlefeatureImageFileUpload}
                                    zoom={zoom}
                                    position={position}
                                    setZoom={handleZoom}
                                    setPosition={handlePosition}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div
                          id="newpageImageUpload"
                          className={
                            isDragging
                              ? "draging newpageImageUpload"
                              : "newpageImageUpload"
                          }
                          onClick={() =>
                            document.getElementById("feature-img").click()
                          }
                        >
                          {loadingFeatureImg === true ? (
                            <div className="featureImg-loader">
                              <Loader />
                            </div>
                          ) : (
                            <div className="featureImgMiddleContent">
                              <img className="profile-cover" src={avatar} alt="avatar-Images" />
                              <p className="uploadimgs-text">
                                Drag and drop an image or click to upload
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                    <input
                      type="file"
                      ref={fileInputRef}
                      id="feature-img"
                      onChange={handlefeatureImageFileUpload}
                      accept="image/*"
                      hidden
                    />
                  </div>
                ) : (
                  <div>
                    <div className="crop-container">
                      {featureImage && oldImage && (
                        <ImageEditor
                          type="featureImg"
                          image={featureImage}
                          oldImage={oldImage}
                          setFeatureImage={setFeatureImage}
                          setUploadedImage={setUploadedImage}
                          replaceImage={handlefeatureImageFileUpload}
                          zoom={zoom}
                          position={position}
                          setZoom={handleZoom}
                          setPosition={handlePosition}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div className="replaceImageRange">
                  <div className="replaceImgBtn">
                    <button
                      className="uploadImage"
                      type="button"
                      onClick={() => handleReplaceImg()}
                    >
                      <span>Replace Image</span>
                    </button>
                  </div>
                  <div className="imgRange">
                    <img className="coverImgLeft" src={coverImg} alt="cover-Images" />
                    <input
                      id="zoom-range"
                      className="zoom-range"
                      type="range"
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      onTouchStart={handleTouchStart}
                      onTouchEnd={handleTouchEnd}
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aspect={384 / 200}
                      aria-labelledby="Zoom"
                      style={{
                        background: `linear-gradient(to right, #344054 ${((zoom - 1) / 2) * 100
                          }%, #B7BCC2 0%)`,
                      }}
                      onChange={(e) => {
                        handleZoom(e.target.value);
                      }}
                    />
                    <img className="coverImgRight" src={coverImg} alt="cover-Images" />
                  </div>
                </div>
                <div className="featuredImageModalbtn">
                  <button
                    className="cancel"
                    type="button"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    className="savePicture"
                    type="button"
                    onClick={() => handleSavePicture()}
                  >
                    Save Picture
                  </button>
                </div>
              </div>
            </div>
          </div>

          {featuredImageModal && <div className="overlayModal"> </div>}
        </>
      )}
      {profileCoverModal && (
        <>
          <div className="profile-img-model imagCropModal">
            <div
              className="featuredImageModal featurboxModal"
              ref={profileModalRef}
            >
              <div className="modal-content">
                {uploadedProfileImage === null ? (
                  <div
                    className="newPageRightRow"
                    onDragOver={handleDragOver}
                    onDragLeave={handleProfileDragLeave}
                    onDrop={handleDrop}
                  >
                    {((saveProfileImage && saveProfileImage === oldProfileImage) || saveProfileImage !== image) &&
                      (saveProfileImage ? (
                        <div id="newpageImageUpload" className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" } >
                          {loadingProfileImg === true ? (
                            <div className="featureImg-loader">
                              <Loader />
                            </div>
                          ) : (
                            <>
                              <div>
                                <div className="crop-container">
                                  <ImageEditor
                                    type="profileImg"
                                    image={image}
                                    oldImage={oldProfileImage}
                                    setOldImage={setOldProfileImage}
                                    setFeatureImage={setImage}
                                    setUploadedImage={setUploadedProfileImage}
                                    replaceImage={handleFileUpload}
                                    zoom={profileZoom}
                                    position={profilePosition}
                                    setZoom={handleProfileZoom}
                                    setPosition={handleProfilePosition}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div
                          id="newpageImageUpload"
                          className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" }
                          onClick={() =>
                            document.getElementById("upload-img").click()
                          }
                        >
                          {loadingProfileImg === true ? (
                            <div className="featureImg-loader">
                              <Loader />
                            </div>
                          ) : (
                            <div className="featureImgMiddleContent">
                              <img className="profile-cover" src={avatar} alt="avatar-Images" />
                              <p className="uploadimgs-text">
                                Drag and drop an image or click to upload
                              </p>
                            </div>
                          )}
                        </div>
                      ))}

                    {(image && saveProfileImage === image) &&
                      (image ? (
                        <div
                          id="newpageImageUpload"
                          className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" }
                        >
                          {loadingProfileImg === true ? (
                            <div className="featureImg-loader">
                              <Loader />
                            </div>
                          ) : (
                            <>
                              <div>
                                <div className="crop-container">
                                  <ImageEditor
                                    type="profileImg"
                                    image={image}
                                    oldImage={oldProfileImage}
                                    setOldImage={setOldProfileImage}
                                    setFeatureImage={setImage}
                                    setUploadedImage={setUploadedProfileImage}
                                    replaceImage={handleFileUpload}
                                    zoom={profileZoom}
                                    position={profilePosition}
                                    setZoom={handleProfileZoom}
                                    setPosition={handleProfilePosition}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div
                          id="newpageImageUpload"
                          className={ isDragging ? "draging newpageImageUpload" : "newpageImageUpload" }
                          onClick={() =>
                            document.getElementById("upload-img").click()
                          }
                        >
                          {loadingProfileImg === true ? (
                            <div className="featureImg-loader">
                              <Loader />
                            </div>
                          ) : (
                            <div className="featureImgMiddleContent">
                              <img
                                className="profile-cover"
                                src={avatarProfile}
                                alt="avatarProfile"
                              />
                              <p className="uploadimgs-text">
                                Drag and drop an image or click to upload
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                    <input
                      type="file"
                      ref={profileFileInputRef}
                      id="upload-img"
                      onChange={handleFileUpload}
                      accept="image/*"
                      hidden
                    />
                  </div>
                ) : (
                  <div>
                    <div className="crop-container">
                      {image && oldProfileImage && (
                        <ImageEditor
                          image={image}
                          oldImage={oldProfileImage}
                          setOldImage={setOldProfileImage}
                          setFeatureImage={setImage}
                          setUploadedImage={setUploadedProfileImage}
                          replaceImage={handleFileUpload}
                          zoom={profileZoom}
                          position={profilePosition}
                          setZoom={handleProfileZoom}
                          setPosition={handleProfilePosition}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div className="replaceImageRange">
                  <div className="replaceImgBtn">
                    <button
                      className="uploadImage"
                      type="button"
                      onClick={() => handleReplaceProfileImg()}
                    >
                      <span>Replace Image</span>
                    </button>
                  </div>
                  <div className="imgRange">
                    <img className="coverImgLeft" src={coverImg} alt="cover-Images" />
                    <input
                      id="zoom-range"
                      className="zoom-range"
                      type="range"
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      onTouchStart={handleTouchStart}
                      onTouchEnd={handleTouchEnd}
                      value={profileZoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aspect={384 / 200}
                      aria-labelledby="Zoom"
                      style={{
                        background: `linear-gradient(to right, #344054 ${((profileZoom - 1) / 2) * 100
                          }%, #B7BCC2 0%)`,
                      }}
                      onChange={(e) => {
                        handleProfileZoom(e.target.value);
                      }}
                    />
                    <img className="coverImgRight" src={coverImg} alt="cover-Images" />
                  </div>
                </div>
                <div className="featuredImageModalbtn">
                  <button className="cancel" type="button" onClick={() => handleProfileCancel()} >
                    Cancel
                  </button>
                  <button
                    className="savePicture"
                    type="button"
                    onClick={() => handleSaveProfilePicture()}
                  >
                    Save Picture
                  </button>
                </div>
              </div>
            </div>
          </div>

          {profileCoverModal && <div className="profileOverlayModal"> </div>}
        </>
      )}
      {showUploadMediaPopup && (
          <UploadMediaPopup showUploadMediaPopup={showUploadMediaPopup} setShowUploadMediaPopup={setShowUploadMediaPopup} setSelectedProfileImage={setSelectedProfileImage} selectMediaLoading={selectMediaLoading} uploadMediaPopupFor={uploadMediaPopupFor} uploadMediaPopupTab={uploadMediaPopupTab}/>
      )}
      {notification && 
        <NotificationMessage  openNotify={notification} setOpenNotify={setNotification} type={'success'} title={`Your settings has been successfully updated!`} />
      }
      {(subscriberNotify && subscriberCount > 0) && (
          <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
      )}
      {subscriberAddedNotify && (
                    <NotificationMessage  openNotify={subscriberAddedNotify} setOpenNotify={setSubscriberAddedNotify} type={'success'} title={messages} />
                )}
    </div>
  );
}

export default General;
