import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState, useEffect, useRef } from 'react';

const TwitterNodeView = ({ node, editor, getPos }) => {
  const [twitterUrl, setTwitterUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setTwitterUrl(trimmedValue);
    setErrorMessage("");
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      if (twitterUrl !== '') {
        const twitterPattern = /^(https?:\/\/)?(www\.)?twitter\.com\/.+\/status\/.+$/;
        const twitterXPattern = /^(https?:\/\/)?(www\.)?x\.com\/.+\/status\/.+$/;

        if (twitterPattern.test(twitterUrl) || twitterXPattern.test(twitterUrl)) {
          editor.chain().focus().setTwitterBlock({
            twitterUrl: twitterUrl,
          }).run();
          setErrorMessage("");
        } else {
          setErrorMessage("Please enter a valid URL");
        }
      }
    }
  };

  // Load the Twitter widget script when the component mounts
  useEffect(() => {
    // Load Twitter's widgets.js script
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <NodeViewWrapper>
      <div data-type="twitter" className="twitter-node-wrapper" data-drag-handle>
        <label>
          <div className='urlInput'>
          <svg width='20' height='20' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <rect width='14' height='14' rx='7' fill='#0A0A0A' />
                                        <path d='M7.58666 6.53049L9.8801 3.92188H9.3367L7.3444 6.18643L5.75432 3.92188H3.91992L6.32498 7.34658L3.91992 10.0819H4.46332L6.56595 7.68987L8.24553 10.0819H10.0799L7.58666 6.53049ZM6.84215 7.37664L6.59809 7.03541L4.65929 4.32273H5.49406L7.0592 6.51276L7.30223 6.854L9.33644 9.70055H8.50167L6.84215 7.37664Z' fill='white' />
                                    </svg>
            <input
              className="urlInputLeft"
              type="text"
              placeholder="Paste X (formerly Twitter) URL to add embedded content"
              value={twitterUrl}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            {
              errorMessage &&
              <p className="error-message">{errorMessage}</p>
            }
          </div>
        </label>
      </div>
    </NodeViewWrapper>
  );
};

export const TwitterEmbeds = Node.create({
    name: 'twitter',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,
    selectable: true,
    inline: false,

    addAttributes() {
        return {
            twitterUrl: {
                default: '',
            },
            embedHtml: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ node }) {
        return [
            'div',
            { 'data-type': this.name },
            node.attrs.embedHtml || '',  // Render the embedded tweet's HTML content
        ];
    },

    addCommands() {
        return {
            setTwitterEmbeds: () => ({ commands }) =>
                commands.insertContent(`<div data-type="${this.name}"></div>`),
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(TwitterNodeView);
    },
});

