import { useState, useEffect } from 'react';
import moment from 'moment';
import '.././App.css';
import search from '.././images/search.svg'
import clear from '.././images/delete.svg'
import down from '.././images/down_arrow.svg'
import top from '.././images/arrow_top.svg'
import NewsletterListItem from '../Components/NewsletterListItem';
import Pagination from '../Components/Pagination';
import axios from 'axios';
import NoResults from '../Components/NoResults';
import MultipleDeletePages from '../Components/DeleteModal/MultipleDeletePages';
import SingleDeletePage from '../Components/DeleteModal/SingleDeletePage';
import NotificationMessage from '../Components/NotificationMessage';


function Newsletter(props) {

    props.setActivePage('Newsletter')
    const [activeFilter, setActiveFilter] = useState('All')
    const [searchInput, setSearchInput] = useState('')
    const [pages, setPages] = useState([])
    const [allFiltered, setAllFiltered] = useState(pages)
    const [itemToDelete, setItemToDelete] = useState('')
    const [allSelected, setAllSelected] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [isPopupOpenDeleteAccount, setIsPopupOpenDeleteAccount] = useState(false);
    const [isPopupSingleDeleteAccount, setIsPopupSingleDeleteAccount] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPageStatus, setSelectedPageStatus] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const [showMultiNotify, setShowMultiNotify] = useState(false)
    const [selectedPubLenght, setSelectedPubLenght ] = useState(0)
    const [subscriberNotify, setSubscriberNotify] = useState(false);
    const [subscriberCount, setSubscriberCount]= useState(0)

    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id

    useEffect(() => {
        searchPage()
    }, [searchInput])

    useEffect(() => {
        var id = itemToDelete;

        var result = [];

        pages.forEach(element => {
            if (element.id === id) {
                return false
            } else {
                result.push(element)
            }
        });

        setPages(result)
        setAllFiltered(result)

    }, [itemToDelete])

    const data = async () => {
        const search = searchInput.toLowerCase();
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/newsLetter/list/${user_id}?page=${currentPage}&search=${search}&status=${selectedPageStatus}`,
            )
            const result = response?.data?.result;

            setOriginalData(result);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }
    useEffect (()=>{
        data();
    },[user_id,currentPage, searchInput, selectedPageStatus])

    const userData = async () =>{
        try{
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )
            setSubscriberCount(response?.data?.subscriberCount)
            if(response?.data?.subscriberCount > 0){
                setSubscriberNotify(true)
            }
        }catch(error){
            console.log(error, "error");  
        }
    }
    useEffect(()=>{
        userData()
    },[])

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedPageStatus]);
    function searchPage() {

        var result = [];
        var input = searchInput.toLowerCase()
        originalData.forEach(element => {
            var title = element?.title?.toLowerCase();
            if (title?.startsWith(input)) {
                result.push(element);
            }
        });

        setPages(result);
        setAllFiltered(result);

        if (result != []) {
            setPages(result)
            setAllFiltered(result)
        } else if (input === '') {
            setPages(originalData)
            setAllFiltered(originalData)
            setActiveFilter('All')
        } else {
            setPages([])
            setAllFiltered([])
        }
    }

    /* SORTING */

    const [titleSort, setTitleSort] = useState(false)

    function sortByName() {
        var result;

        if (titleSort === true) {
            result = [...pages].sort((a, b) => a.title.localeCompare(b.title))
        } else {
            result = [...pages].sort((a, b) => -1 * a.title.localeCompare(b.title))
        }

        setPages(result)
    }


    const [statusSort, setStatusSort] = useState(false)

    function sortByStatus() {
        var result;

        if (statusSort === true) {
            result = [...pages].sort((a, b) => a.status.localeCompare(b.status))
        } else {
            result = [...pages].sort((a, b) => -1 * a.status.localeCompare(b.status))
        }

        setPages(result)
    }


    const [dateSort, setDateSort] = useState(false)

    function sortByDate() {
        var result = [];

        if (dateSort === false) {

            result = [...pages].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format('ll');
                var daysA = moment(dateA).diff(now, 'Days');

                var dateB = moment(b.createdAt).format('ll');
                var daysB = moment(dateB).diff(now, 'Days');
                return daysA - daysB;
            })
        } else {
            result = [...pages].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format('ll');
                var daysA = moment(dateA).diff(now, 'Days');

                var dateB = moment(b.createdAt).format('ll');
                var daysB = moment(dateB).diff(now, 'Days');
                return daysB - daysA;
            })
        }
        setPages(result)
    }

    const [lastUpdate, setLastUpdate] = useState(false)

    function sortByLastUpdate() {
        var result = [];

        if (lastUpdate === false) {

            result = [...pages].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.updatedAt).format('ll');
                var daysA = moment(dateA).diff(now, 'Days');

                var dateB = moment(b.updatedAt).format('ll');
                var daysB = moment(dateB).diff(now, 'Days');
                return daysA - daysB;
            })
        } else {
            result = [...pages].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.updatedAt).format('ll');
                var daysA = moment(dateA).diff(now, 'Days');

                var dateB = moment(b.updatedAt).format('ll');
                var daysB = moment(dateB).diff(now, 'Days');
                return daysB - daysA;
            })
        }
        setPages(result)
    }

    const [originalData, setOriginalData] = useState([]);

    function updateOriginalData(updatedData) {
        setOriginalData(updatedData);
        // setSelectedItems([])
        fetchData()
    }

    const fetchData = async () => {
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/newsLetter/list/${JSON.parse(localStorage.getItem("userinfo"))?._id}?page=${currentPage}&status=${selectedPageStatus}`,
            )
            setOriginalData(response?.data?.result);
            setTotalPage(response?.data?.totalPages || 1);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }





    const [messages, setMessages] = useState("");
    const [subscriberAddedNotify, setSubscriberAddedNotify] = useState(false);
    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8080');
    
        webSocket.onopen = () => {
        //   console.log('Connected to WebSocket server');

        };
    
        webSocket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData?.author_id === user_id){
                if(parsedData?.message !== "" && parsedData?.message !== undefined){
                    setSubscriberAddedNotify(true);
                    setMessages(parsedData?.message);
                }
            }
        };
    
        return () => {
            webSocket.close();
        };
    }, []);
    useEffect(() => {
        fetchData();
    }, [selectedPageStatus]);

    useEffect(() => {
        filterByStatus(activeFilter);
    }, [activeFilter, originalData]);

    function filterByStatus(status) {
        if (status === 'All') {
            setPages(originalData);
        } else {
            const filteredData = originalData.filter(item => item.status === status);
            setPages(filteredData);
        }
    }
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
          if (e.target != null && isPopupOpenDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
            setIsPopupOpenDeleteAccount(false);
          }
          if (e.target != null && isPopupSingleDeleteAccount && (e.target.classList.contains('welcomeOverlay'))) {
            setIsPopupSingleDeleteAccount(false);
        }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
    
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside);
        };
      }, [isPopupOpenDeleteAccount, isPopupSingleDeleteAccount]);

    const handleDelete = async () => {
        if (selectedItems.length === 1 && (selectedItems.status !== "Deleted" || selectedItems.status === "Deleted")) {
            setIsPopupSingleDeleteAccount(true);
            document.body.classList.add('hidden-message');
        }

        if (selectedItems.length > 1 && (selectedItems.status !== "Deleted" || selectedItems.status === "Deleted")) {
            setIsPopupOpenDeleteAccount(true);
            document.body.classList.add('hidden-message');
        }
    };

    const closeSingleAccountDeletedPopup = () => {
        // setSelectedItems([]);
        document.body.classList.remove('hidden-message');
        setIsPopupSingleDeleteAccount(false);
    }

    const closeAccountDeletedPopup = () => {
        // setSelectedItems([]);
        document.body.classList.remove('hidden-message');
        setIsPopupOpenDeleteAccount(false)
    };

    useEffect(() => {
        setSelectAll(false); 
        filterByStatus(activeFilter);
    }, [activeFilter, originalData]);

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter((id) => id !== itemId);
                setSelectAll(updatedSelectedItems.length === pages.length);
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(updatedSelectedItems.length === pages.length);
                return updatedSelectedItems;
            }
        });
    };

    const [openPopupIndex, setOpenPopupIndex] = useState(null);

    const togglePopup = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(null);
        } else {
            setOpenPopupIndex(index);
        }
    };

    const updateStatus = async (itemId, newStatus) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/newsLetter/update/${itemId}`,
                {
                    status: newStatus
                });
            fetchData();
        } catch (error) {
            console.log(error, "error");
        }
    };



    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = pages.map((item) => item._id);
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };


    return (
        <section className='dashboard'>
            <div className="container">
                <div className="dashboardTop">
                    <div className="dashboardTopText">
                        <div className='dashboardTopLeft'>
                            <p>Newsletter</p>
                            <p>Track, manage and forecast your customers and orders.</p>
                        </div>
                    </div>
                </div>

                <div className="pagesSorting">
                    <div className='pagesSortingButtons'>
                        <button className={(activeFilter === 'All') ? 'pagesSortingActive' : ''} onClick={e => { setActiveFilter('All'); setSelectedPageStatus(''); setSelectedItems([]); setAllSelected(false) }}>All newsletters</button>
                        <button className={(activeFilter === 'Send') ? 'pagesSortingActive' : ''} onClick={e => { setActiveFilter('Send'); setSelectedPageStatus('Send'); setSelectedItems([]); setAllSelected(false) }}>Sent</button>
                        <button className={(activeFilter === 'Draft') ? 'pagesSortingActive' : ''} onClick={e => { setActiveFilter('Draft'); setSelectedPageStatus('Draft'); setSelectedItems([]); setAllSelected(false) }}>Drafts</button>
                        <button className={(activeFilter === 'Deleted') ? 'pagesSortingActive' : ''} onClick={e => { setActiveFilter('Deleted'); setSelectedPageStatus('Deleted'); setSelectedItems([]); setAllSelected(false) }}>Deleted</button>
                    </div>

                    <div className='pagesSearch'>
                        <div className="pageSearchInput">
                            <input type="text" name="pages_search" id="pages_search" placeholder={'Search'} value={searchInput} onChange={e => setSearchInput(e.target.value)} />

                            <img src={search} alt="Search" />

                        </div>

                        <div className='deleteIcon' onClick={() => handleDelete()}>
                            <button className='deleteTooltip' >Delete</button>
                            <img className='deleteIconImage' src={clear} alt="Clear Input" />
                        </div>

                    </div>
                </div>

                <div className="pagesTableWrapper ">
                    <div className={(pages.length > 0) ? 'pagesTable newsletterTable' : 'pagesTable newsletterTable tableBottomRounded'}>
                        <div className="pagesTableTop">
                            <div>
                                <input type={'checkbox'} checked={selectAll} onChange={handleSelectAll} />
                                <p onClick={e => {
                                    setTitleSort(!titleSort)
                                    sortByName()
                                }}>Subject</p>
                                <img src={(titleSort === false) ? down : top} alt="Down" onClick={e => {
                                    setTitleSort(!titleSort)
                                    sortByName()
                                }} />
                            </div>
                            <div onClick={e => {
                                setStatusSort(!statusSort)
                                sortByStatus()
                            }} >Status  <img src={(statusSort === false) ? down : top} alt="Down" /></div>
                            <div onClick={e => {
                                sortByDate()
                                setDateSort(!dateSort)
                            }}>To  <img src={(dateSort === false) ? down : top} alt="Down" /></div>
                            <div onClick={e => {
                                setLastUpdate(!lastUpdate);
                                sortByLastUpdate()
                            }}>Date  <img src={(lastUpdate === false) ? down : top} alt="Down" /></div>
                            <div>Link</div>
                            <div>Actions</div>
                        </div>

                        { isLoading ? 
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                            :
                            (pages?.length > 0 && (activeFilter !== 'All' || pages.some(item => item.status !== 'Deleted'))) ? (
                                pages?.map((item, index) => (
                                    (activeFilter !== 'Deleted' && item.status === 'Deleted') ? '' : (
                                        <NewsletterListItem updateStatus={updateStatus} isOpen={index === openPopupIndex} togglePopup={() => togglePopup(index)} type="newsLetter" allSelected={allSelected} setItemToDelete={setItemToDelete} setSelectedItems={setSelectedItems} selectedItems={selectedItems} data={item} key={index} index={index} updateOriginalData={updateOriginalData} setPages={setPages} handleCheckboxChange={handleCheckboxChange} setShowNotification={setShowNotification} />
                                    )
                                ))
                            ) : (
                                    <NoResults type="newsletter" name="Newsletters" />
                            )
                        }
                    </div>
                </div>
                {
                    (totalPage > 1) && <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                }
            </div>

            {(isPopupOpenDeleteAccount || isPopupSingleDeleteAccount) && <div className="welcomeOverlay"></div>}
            
            { <SingleDeletePage isOpen={isPopupSingleDeleteAccount} onClose={closeSingleAccountDeletedPopup} data={pages} type='newsLetter' updateOriginalData={updateOriginalData} index={selectedItems} activeFilter={activeFilter} setShowNotification={setShowNotification}/> }
            { <MultipleDeletePages isOpen={isPopupOpenDeleteAccount} onClose={closeAccountDeletedPopup} customKey="newsLetterId" type='newsLetter' name="newsLetters" selectedItems={selectedItems} updateOriginalData={updateOriginalData} activeFilter={activeFilter} setShowMultiNotify={setShowMultiNotify} setSelectedPubLenght={setSelectedPubLenght} setSelectedItems={setSelectedItems}/> }
            {showNotification && (
                <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`The newsletter was successfully deleted!`} />
            )}
            {showMultiNotify && (
                <NotificationMessage  openNotify={showMultiNotify} setOpenNotify={setShowMultiNotify} type={'success'} title={`${selectedPubLenght} newsletters were successfully deleted!`} />
            )}
            {(subscriberNotify && subscriberCount > 0) && (
                <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
            )}


            {subscriberAddedNotify && (
                    <NotificationMessage  openNotify={subscriberAddedNotify} setOpenNotify={setSubscriberAddedNotify} type={'success'} title={messages} />
                )}
        </section>
    );
}

export default Newsletter;
