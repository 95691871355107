import React, { useEffect, useState } from "react";
import axios from 'axios';

import Pagination from '../Components/Pagination';
import NoResults from '../Components/NoResults';
import CategoriesPageListItem from '../Components/CategoriesPageListItem';
import AddCategoryPage from "../Components/Modal/AddCategoryPage";
import DeleteCategoryModel from "../Components/Modal/DeleteCategoryModel";

import '.././App.css';
import search from '.././images/search.svg'
import down from '.././images/down_arrow.svg'
import top from '.././images/arrow_top.svg'
import clear from '.././images/delete.svg'
import plus from '.././images/plus-icon.svg'
import NotificationMessage from "../Components/NotificationMessage";

function Categories(props) {

    props.setActivePage('Categories');

    const [activeFilter, setActiveFilter] = useState('All')
    const [searchInput, setSearchInput] = useState('')

    const [originalData, setOriginalData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([])

    const [selectAll, setSelectAll] = useState(false);
    const [allSelected, setAllSelected] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [selectedCatsLength, setSelectedCatsLength] = useState(0)

    const [nameSort, setNameSort] = useState(false)
    const [pageSort, setPageSort] = useState(false)
    const [pubicSort, setPubicSort] = useState(false)
    const [newsSort, setNewsSort] = useState(false)

    const [openPopupIndex, setOpenPopupIndex] = useState(null);

    const [showCategoryField, setShowCategoryField] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const [openNotify, setOpenNotify] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showMultiNotify, setShowMultiNotify] = useState(false)
    const [subscriberNotify, setSubscriberNotify] = useState(false);
    const [subscriberCount, setSubscriberCount]= useState(0)

    const [messages, setMessages] = useState("");
    const [subscriberAddedNotify, setSubscriberAddedNotify] = useState(false);
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id

    const fetchData = async () => {
        const search = searchInput.toLowerCase();        
        const types = activeFilter != 'All' ? activeFilter : '';
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/list/${JSON.parse(localStorage.getItem("userinfo"))?._id}?page=${currentPage}&search=${search}&types=${types}`,
            )
            setOriginalData(response?.data?.result);
            setCategoriesData(response?.data?.result);
            setTotalPage(response.data.totalPages || 1);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }
    useEffect(() => {
        fetchData();
    }, [currentPage, searchInput, activeFilter]);

    const userData = async () =>{
        try{
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )
            setSubscriberCount(response?.data?.subscriberCount)
            if(response?.data?.subscriberCount > 0){
                setSubscriberNotify(true)
            }
        }catch(error){
            console.log(error, "error");  
        }
    }
    useEffect(()=>{
        userData()
    },[])

    const updateCategoriesData = (categoryId, categoryName, action) => {

        if (action == 'add') {
            const categoriesDatas = categoriesData;

            const foundData = categoriesDatas.find(obj => obj.name === categoryName);

            if (foundData) {
                return "This category already exists.";
            }
        }

        if (action == 'update') {
            const categoriesDatas = categoriesData;
            categoriesDatas.push(categoryId);

            setOriginalData(categoriesDatas);
            setCategoriesData(categoriesDatas);
        }


        if (action == 'edit') {
            const categoriesDatas = categoriesData;

            const categoryFound = categoriesDatas.find(obj => obj._id !== categoryId && obj.name === categoryName);

            if (categoryFound !== undefined) {
                return "This category already exists.";
            }

            const foundData = categoriesDatas.find(obj => obj._id === categoryId);

            foundData.name = categoryName;

            const indexId = categoriesDatas.findIndex(item => item._id === foundData._id);

            if (indexId !== -1) {
                categoriesDatas[indexId] = foundData;
            }

            setOriginalData(categoriesDatas);
            setCategoriesData(categoriesDatas);
        }

        if (action == 'delete') {
            const categoriesDatas = categoriesData.filter(item => !categoryId.includes(item._id));

            setOriginalData(categoriesDatas);
            setCategoriesData(categoriesDatas);
        }

        if (action == 'move') {
            const categoriesDatas = categoriesData;

            const foundData = categoriesDatas.find(obj => obj._id === categoryName);
            const foundDeletedData = categoriesDatas.filter(obj => categoryId.includes(obj._id));

            const page_ids = Array.from(new Set([...foundData.page_ids, ...[...new Set(foundDeletedData.flatMap(obj => obj.page_ids))]]));
            const newslatter_ids = Array.from(new Set([...foundData.newslatter_ids, ...[...new Set(foundDeletedData.flatMap(obj => obj.newslatter_ids))]]));
            const publication_ids = Array.from(new Set([...foundData.publication_ids, ...[...new Set(foundDeletedData.flatMap(obj => obj.publication_ids))]]));

            foundData.page_ids = page_ids;
            foundData.newslatter_ids = newslatter_ids;
            foundData.publication_ids = publication_ids;

            foundData.page_count = page_ids.length;
            foundData.newslatter_count = newslatter_ids.length;
            foundData.publication_count = publication_ids.length;

            const indexId = categoriesDatas.findIndex(item => item._id === foundData._id);

            if (indexId !== -1) {
                categoriesDatas[indexId] = foundData;
            }

            setOriginalData(categoriesDatas);
            setCategoriesData(categoriesDatas);

            return foundData;
        }

        return true;
    }

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter((id) => id !== itemId);
                setSelectAll(updatedSelectedItems.length === categoriesData.length);
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(updatedSelectedItems.length === categoriesData.length);
                return updatedSelectedItems;
            }
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = categoriesData.map((item) => item._id);
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const sortByName = () => {
        const result = [...categoriesData].sort((a, b) => {
            const nameA = a.name || "";
            const nameB = b.name || "";
            return nameSort ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        });

        setCategoriesData(result);
    }

    const sortByPage = () => {
        const result = [...categoriesData].sort((a, b) => {
            const pageA = parseFloat(a.page_count) || 0;
            const pageB = parseFloat(b.page_count) || 0;
            return pageSort ? pageA - pageB : pageB - pageA;
        });

        setCategoriesData(result);
    }

    const sortByPubic = () => {
        const result = [...categoriesData].sort((a, b) => {
            const publicA = parseFloat(a.publication_count) || 0;
            const publicB = parseFloat(b.publication_count) || 0;
            return pubicSort ? publicA - publicB : publicB - publicA;
        });

        setCategoriesData(result);
    }

    const sortByNews = () => {
        const result = [...categoriesData].sort((a, b) => {
            const newsA = parseFloat(a.newslatter_count) || 0;
            const newsB = parseFloat(b.newslatter_count) || 0;
            return newsSort ? newsA - newsB : newsB - newsA;
        });

        setCategoriesData(result);
    }

    const togglePopup = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(null);
        } else {
            setOpenPopupIndex(index);
        }
    };

    const handleNewCategoryClick = () => {
        setShowCategoryField(true);
        document.body.classList.add('hidden-message');
    }



    const closeCategoryField = (action) => {
        setShowCategoryField(false);
        document.body.classList.remove('hidden-message');
    }

    const handleDelete = () => {
        if (selectedItems.length > 0) {
            setShowDeleteModel(true);
            document.body.classList.add('hidden-message');
        }
    }

    const closeDeleteModel = () => {
        setShowDeleteModel(false);
        document.body.classList.remove('hidden-message');
    }

    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8080');
    
        webSocket.onopen = () => {
        //   console.log('Connected to WebSocket server');

        };
    
        webSocket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData?.author_id === user_id){
                if(parsedData?.message !== "" && parsedData?.message !== undefined){
                    setSubscriberAddedNotify(true);
                    setMessages(parsedData?.message);
                }
            }
        };
    
        return () => {
            webSocket.close();
        };
    }, []);

    return (
        <section className='dashboard categories-page'>
            <div className="container">
                <div className="dashboardTop">
                    <div className="dashboardTopText">
                        <div className='dashboardTopLeft'>
                            <p>Categories</p>
                            <p>Track, manage and forecast your customers and orders.</p>
                        </div>
                    </div>
                </div>

                <div className="pagesSorting">
                    <div className='pagesSortingButtons'>
                        <button className={(activeFilter === 'All') ? 'pagesSortingActive' : ''} onClick={e => { setActiveFilter('All'); setSelectedItems([]); setSelectAll(false) }}>All categories</button>
                        <button className={(activeFilter === 'Pages') ? 'pagesSortingActive' : ''} onClick={e => { setActiveFilter('Pages'); setSelectedItems([]); setSelectAll(false) }}>Pages</button>
                        <button className={(activeFilter === 'Publications') ? 'pagesSortingActive' : ''} onClick={e => { setActiveFilter('Publications'); setSelectedItems([]); setSelectAll(false) }}>Publications</button>
                        <button className={(activeFilter === 'Newsletters') ? 'pagesSortingActive' : ''} onClick={e => { setActiveFilter('Newsletters'); setSelectedItems([]); setSelectAll(false) }}>Newsletters</button>
                    </div>

                    <div className='pagesSearch'>
                        <div className="pageSearchInput">
                            <input type="text" id="pages_search" name="pages_search" placeholder={'Search'} value={searchInput} onChange={e => setSearchInput(e.target.value)} />
                            <img src={search} alt="Search" />
                        </div>

                        <div className='addIcon'>
                            <button className="add-cat-btn" onClick={handleNewCategoryClick}><img src={plus} alt="Icon" /> <span>&nbsp;&nbsp;Add new</span></button>
                        </div>

                        <div className='deleteIcon' onClick={() => handleDelete()}>
                            <button className='deleteTooltip'>Delete</button>
                            <img className='deleteIconImage' src={clear} alt="Clear Input" />
                        </div>

                    </div>
                </div>

                <div className="pagesTableWrapper">
                    <div className="pagesTable publicTable categoryTableItem">
                        <div className="pagesTableTop publicationTableTop">
                            <div>
                                <input type={'checkbox'} checked={selectAll} onChange={handleSelectAll} />
                                <p onClick={e => {
                                    setNameSort(!nameSort)
                                    sortByName()
                                }}>Name
                                </p>
                                <img src={(nameSort === false) ? down : top} alt="Down" onClick={e => {
                                    setNameSort(!nameSort)
                                    sortByName()
                                }} />
                            </div>
                            <div>
                                <p onClick={e => {
                                    setPageSort(!pageSort)
                                    sortByPage()
                                }}>
                                    Pages
                                </p>
                                <img src={(pageSort === false) ? down : top} alt="Down" onClick={e => {
                                    setPageSort(!pageSort)
                                    sortByPage()
                                }} />
                            </div>
                            <div>
                                <p onClick={e => {
                                    setPubicSort(!pubicSort)
                                    sortByPubic()
                                }}>
                                    Publications
                                </p>
                                <img src={(pubicSort === false) ? down : top} alt="Down" onClick={e => {
                                    setPubicSort(!pubicSort)
                                    sortByPubic()
                                }} />
                            </div>
                            <div>
                                <p onClick={e => {
                                    setNewsSort(!newsSort)
                                    sortByNews()
                                }}>
                                    Newsletters
                                </p>
                                <img src={(newsSort === false) ? down : top} alt="Down" onClick={e => {
                                    setNewsSort(!newsSort)
                                    sortByNews()
                                }} />
                            </div>
                            <div>Actions</div>
                        </div>
                        { isLoading? 
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                            :
                            (categoriesData?.length > 0) ? (
                                categoriesData?.map((item, index) => (
                                    <CategoriesPageListItem key={item._id} data={item} handleCheckboxChange={handleCheckboxChange} setSelectedItems={setSelectedItems} selectedItems={selectedItems} isOpen={index === openPopupIndex} togglePopup={() => togglePopup(index)} updateCategoriesData={updateCategoriesData} categoriesData={categoriesData}  setShowNotification={setShowNotification} />
                                ))
                            ) : (
                                <NoResults type="categories" name="categories" onclickhandle={() => handleNewCategoryClick()} />
                            )
                        }
                    </div>
                </div>
                {
                    totalPage > 1 && 
                        <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                }
            </div>

            {(showCategoryField || showDeleteModel) && <div className="welcomeOverlay"></div>}
            {showCategoryField && <AddCategoryPage isOpen={showCategoryField} onClose={closeCategoryField} updateCategoriesData={updateCategoriesData} setOpenNotify={setOpenNotify}/>}
            {showDeleteModel && <DeleteCategoryModel isOpen={showDeleteModel} onClose={closeDeleteModel} categoryIds={selectedItems} updateCategoriesData={updateCategoriesData} categoriesData={categoriesData} setShowNotification={setShowNotification} setShowMultiNotify={setShowMultiNotify} setSelectedItems={setSelectedItems} setSelectedCatsLength={setSelectedCatsLength} />}
            {showNotification && (
                <NotificationMessage  openNotify={showNotification} setOpenNotify={setShowNotification} type={'success'} title={`The category was successfully deleted!`} />
            )}
            {showMultiNotify && (
                <NotificationMessage  openNotify={showMultiNotify} setOpenNotify={setShowMultiNotify} type={'success'} title={`${selectedCatsLength} categories were successfully deleted!`} />
            )}
            {openNotify && 
              <NotificationMessage  openNotify={openNotify} setOpenNotify={setOpenNotify} type={'success'} title={`Category has been successfully added!`} />
            }
            {(subscriberNotify && subscriberCount > 0) && (
                <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
            )}


            {subscriberAddedNotify && (
                    <NotificationMessage  openNotify={subscriberAddedNotify} setOpenNotify={setSubscriberAddedNotify} type={'success'} title={messages} />
                )}
        </section>
    );
}

export default Categories;
