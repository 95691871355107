import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState, useEffect, useRef } from 'react';

const TwitterNodeView = ({ node }) => {
  const tweetRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const embedTweet = async () => {
      const tweetId = node.attrs.twitterUrl.split('/').pop(); // Extract tweet ID from URL
      try {
        // Check if the Twitter widget script is already loaded
        if (!window.twttr) {
          const script = document.createElement('script');
          script.src = 'https://platform.twitter.com/widgets.js';
          script.async = true;
          document.body.appendChild(script);
          
          // Ensure that the Twitter widgets will be available once the script loads
          script.onload = () => {
            if (tweetId) {
              window.twttr.widgets.createTweetEmbed(tweetId, tweetRef.current);
            }
          };
        } else if (window.twttr && tweetId) {
          // If the Twitter widget is already loaded, embed the tweet directly
          await window.twttr.widgets.createTweetEmbed(tweetId, tweetRef.current);
        }
      } catch (error) {
        console.error("Error embedding tweet:", error);
        setErrorMessage("An error occurred while loading the tweet.");
      }
    };

    embedTweet();
  }, [node.attrs.twitterUrl]); // Ensure the useEffect re-runs when the tweet URL changes

  return (
    <NodeViewWrapper>
      <div data-type="twitter" className="twitter-node-wrapper" data-drag-handle>
        {errorMessage ? (
          <p className="error-message">{errorMessage}</p>
        ) : (
          <div ref={tweetRef} className="twitter-embed"></div>
        )}
      </div>
    </NodeViewWrapper>
  );
};

export const TwitterBlock = Node.create({
  name: 'twitterBlock',

  isolating: true,
  defining: true,
  group: 'block',
  draggable: true,

  addAttributes() {
    return {
      twitterUrl: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        getAttrs: (element) => {
          const twitterUrl = element.getAttribute('data-twitter-url') || ''; // Parse the Twitter URL from the data attribute
          return { twitterUrl };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        'data-type': this.name,
        'data-twitter-url': HTMLAttributes.twitterUrl,
        class: 'twitter-node-wrapper'
      },
    ];
  },

  addCommands() {
    return {
      setTwitterBlock:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              twitterUrl: options?.twitterUrl || '', // Set twitterUrl in node attributes
            },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(TwitterNodeView); // Attach TwitterNodeView to render embedded tweet
  },
});
