import { useEffect, useState } from 'react';
import axios from 'axios';

import '../.././App.css';
import Select, { components } from "react-select";
import selectDown from "../../images/Settings/select_down.svg"
import NotificationMessage from '../../Components/NotificationMessage';

const colourStyles = {
    option: (styles, { isFocused, isSelected, isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? "#007AFF"
          : isFocused
            ? "#f9f9f9"
            : "transparent",
        fontSize: "14px",
        cursor: "pointer",
      };
    },
    input: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: 0,
      margin: 0,
      borderRadius: "8px",
      fontSize: "14px",
      cursor: "pointer",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: "#101828",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: "8px",
      width: "100%",
    }),
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none !important",
      borderRadius: "8px",
      marginRTop: "2px",
    }),
    container: (provided, state) => ({
      ...provided,
      paddingLeft: "0px",
    }),
    placeholder: (styles, { isFocused }) => {
      return {
        ...styles,
        marginBottom: isFocused ? "0px" : "0px",
        fontSize: "14px",
        marginLeft: "0",
        color: "#667085",
      };
    },
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      outline: "none",
      marginRight: "5px",
      position: 'relative',
      top: '4px'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: "15px",
      paddingRight: "15px",
    }),
};

function Notification(props) {

    props.setActivePage('Notifications')

    const [enableNotification, setEnableNotification] = useState(false);
    const [weeklyReport, setWeeklyReport] = useState(false)
    const [monthlyReport, setMonthlyReport] = useState(false)
    const [yearlyReport, setYearlyReport] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [email, setEmail] = useState("")
    const [sendMailSubscriber, setSendMailSubscriber] = useState('Everyone');
    const [subscriberCount, setSubscriberCount]= useState(0)
    const [subscriberNotify, setSubscriberNotify] = useState(false);
    
    const subscriberInterval = [{ value: "Everyone", label: "Everyone"},{ value: "All Subscribers", label: "All Subscribers"},{ value: "Paid Subscribers", label: "Paid Subscribers"},{ value: "Nobody", label: "Nobody"}];

    function clearAll() {
        setCancelLoading(true);
        fetchData();
    }

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            )
            const userMeta = response?.data?.result?.userMeta;
            setEmail(userMeta?.email)
            setEnableNotification(userMeta.notification_type);
            setWeeklyReport(userMeta.notification_weekly);
            setMonthlyReport(userMeta.notification_monthly);
            setYearlyReport(userMeta.notification_yearly);
            setSendMailSubscriber(userMeta?.emailReplies || "Everyone");
            setSubscriberCount(response?.data?.subscriberCount)

            if(response?.data?.subscriberCount > 0){
                setSubscriberNotify(true)
            }
            setCancelLoading(false);
        } catch (error) {
            setCancelLoading(false);
            console.log(error, "error");
        }
    }


    const addData = async () => {
        try {
            setIsLoading(true);
            const notificationData = {
                notification_type: enableNotification,
                notification_weekly: weeklyReport,
                notification_monthly: monthlyReport,
                notification_yearly: yearlyReport,
                notification_receive_mail: sendMailSubscriber,
                emailReplies: sendMailSubscriber
            }

            await axios.post(
                `${process.env.REACT_APP_API_URL}/user/changeNotification/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                notificationData,
            )
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "Data not add");
        }
    }

    const handleEmailsent = (selectedOption) => {
        setSendMailSubscriber(selectedOption?.value);
      };
      const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={selectDown} alt="select-down" />
          </components.DropdownIndicator>
        );
      };
      const [messages, setMessages] = useState("");
    const [subscriberAddedNotify, setSubscriberAddedNotify] = useState(false);
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8080');
    
        webSocket.onopen = () => {
        //   console.log('Connected to WebSocket server');

        };
    
        webSocket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData?.author_id === user_id){
                if(parsedData?.message !== "" && parsedData?.message !== undefined){
                    setSubscriberAddedNotify(true);
                    setMessages(parsedData?.message);
                }
            }
        };
    
        return () => {
            webSocket.close();
        };
    }, []);
    return (
        <div className="general">
            <div className="generalTop">
                <div className="generalTopLeft">
                    <p className='generalTopLeftTitle'>Notifications</p>
                    <p className='generalTopLeftDesc'>Update your company photo and details here.</p>
                </div>
            </div>

            <div className="billingContent">
                <div className="billingRow">
                    <div className="billingText">
                        <p className='settingsMenuBlockTitle'>Analytics email notifications</p>
                        <p className='generalTopLeftDesc'>This will be displayed on your profile.</p>
                    </div>
                    <div>
                        <div className="bigSwitch">
                            <div className={(enableNotification === true) ? 'switch switchToggleActive' : 'switch'} onClick={e => setEnableNotification(!enableNotification)}>
                                <div className='switchToggle'></div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    (enableNotification === true)
                        ? 
                        <>
                        <div className="billingRow">
                            <div className="billingText">
                                <p className='settingsMenuBlockTitle'>Email frequency</p>
                                <p className='generalTopLeftDesc'>This will be displayed on your profile.</p>
                            </div>
                            <div className='switchBlocks'>
                                <div className="switchBlock">
                                    <div className="smallSwitch">
                                        <div className={(weeklyReport === true) ? 'switch switchToggleActive' : 'switch'} onClick={e => setWeeklyReport(!weeklyReport)}>
                                            <div className='switchToggle'></div>
                                        </div>
                                    </div>
                                    <p>Weekly report</p>
                                </div>
                                <div className="switchBlock">
                                    <div className="smallSwitch">
                                        <div className={(monthlyReport === true) ? 'switch switchToggleActive' : 'switch'} onClick={e => setMonthlyReport(!monthlyReport)}>
                                            <div className='switchToggle'></div>
                                        </div>
                                    </div>
                                    <p>Monthly report</p>
                                </div>
                                <div className="switchBlock">
                                    <div className="smallSwitch">
                                        <div className={(yearlyReport === true) ? 'switch switchToggleActive' : 'switch'} onClick={e => setYearlyReport(!yearlyReport)}>
                                            <div className='switchToggle'></div>
                                        </div>
                                    </div>
                                    <p>Yearly report</p>
                                </div>
                            </div>
                        </div>
                        </>
                        : ''
                }
                        <div className="billingRow">
                            <div className="billingText billingTextNotification">
                                <p className='settingsMenuBlockTitle'>Receive email replies</p>
                                <p className='generalTopLeftDesc'>Set who can reach your via email by replying to your newsletter or by emailing {email}</p>
                            </div>
                            <div className='switchBlocks'>
                                <div className="switchBlock">
                                        <Select
                                            className="timezome-selection timezome-selection-notification subscriberSelect"
                                            value={{ value: sendMailSubscriber, label: sendMailSubscriber }}
                                            onChange={handleEmailsent}
                                            options={subscriberInterval}
                                            styles={colourStyles}
                                            components={{ DropdownIndicator }}
                                            isSearchable={false}
                                        />
                                </div>
                            </div>
                        </div>

            </div>

            <div className="saveButtons">
                <button  className={`settingsWhite ${cancelLoading ? 'btn_loading' : ''}`} onClick={e => clearAll()}>
                    {cancelLoading ?
                        <div className="settingPage-cancelLoader ">
                            <div className="ring-loader cancel"></div>
                        </div>
                        : "Cancel"}
                </button>
                <button className={`settingsBlue ${isLoading ? 'btn_loading' : ''}`} onClick={addData}>
                    {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        : "Save"}
                </button>
            </div>
            {(subscriberNotify && subscriberCount > 0) && (
                <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
            )}
            {subscriberAddedNotify && (
                    <NotificationMessage  openNotify={subscriberAddedNotify} setOpenNotify={setSubscriberAddedNotify} type={'success'} title={messages} />
                )}
        </div>
    );
}

export default Notification;
