import { useEffect, useState } from 'react';
import '../.././App.css';

import AddFactor from '../../Components/Settings/AddFactor';
import axios from 'axios';
import Notification from '../../Components/Notification';
import NotificationMessage from '../../Components/NotificationMessage';

function TwoFactor(props) {

    props.setActivePage('2FA')

    const [isAddFactorOpen, setIsAddFactorOpen] = useState(false);
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState(false);
    const [editNotify, setEditNotify] = useState(false);
    const [subscriberCount, setSubscriberCount]= useState(0)
    const [subscriberNotify, setSubscriberNotify] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`
                );
                setSubscriberCount(response?.data?.subscriberCount)
                const userMeta = response?.data?.result?.userMeta;

                let twoFactorAuthData = userMeta?.twoFactorAuth || { status: "disabled", method: "", data: "" };

                twoFactorAuthData = {
                    ...twoFactorAuthData,
                    email: response?.data?.result?.user?.email || ''
                }
                if(response?.data?.subscriberCount > 0){
                    setSubscriberNotify(true)
                }

                setStatus(twoFactorAuthData);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [isAddFactorOpen]);

    const disabledTwoFactor = async() =>{
        setIsLoading(true);
        const updateData = {
            twoFactorAuth: {
                status: "disabled",
                method: "",
                data: ""
            }
        }
        await axios.post(
            `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            updateData
        );
        setStatus(prevState => ({
            ...prevState,
            status: 'disabled'
        }));

        setIsLoading(false);
    }

    const [messages, setMessages] = useState("");
    const [subscriberAddedNotify, setSubscriberAddedNotify] = useState(false);
    const user_id = JSON.parse(localStorage.getItem("userinfo"))?._id
    useEffect(() => {
        const webSocket = new WebSocket('ws://localhost:8080');
    
        webSocket.onopen = () => {
        //   console.log('Connected to WebSocket server');

        };
    
        webSocket.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if(parsedData?.author_id === user_id){
                if(parsedData?.message !== "" && parsedData?.message !== undefined){
                    setSubscriberAddedNotify(true);
                    setMessages(parsedData?.message);
                }
            }
        };
    
        return () => {
            webSocket.close();
        };
    }, []);
    return (
        <div className="general twoFactor">
            <div className="generalTop">
                <div className="generalTopLeft">
                    <p className='generalTopLeftTitle'>Two Factor Authentication</p>
                    <p className='generalTopLeftDesc'>Update your company photo and details here.</p>
                </div>
            </div>

            <div className="twoFactorContent">
                <div className="twoFactorRow">
                    <div className="billingText">
                        <p className='settingsMenuBlockTitle'>Enable 2FA</p>
                        <p className='twoFactorTopLeftDesc'>To enhance the security of your account, we strongly recommend setting up two-factor authentication (2FA).</p>
                    </div>
                    <div className="factorRowCheckbox">
                        <button className="addFactor_btn" onClick={(e) => { setIsAddFactorOpen(!isAddFactorOpen) }}>
                            { (status?.status === "enable") ? "Change 2FA" : "Add 2FA" }                            
                        </button>
                        {(status?.status === "enable")  && 
                            <button className="removeFactor_btn" onClick={(e) => { disabledTwoFactor() }}>
                                {isLoading ?
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                    :
                                    "Disable 2FA"
                                }
                            </button>
                        }
                    </div>
                </div>
            </div>

            {isAddFactorOpen &&
                <AddFactor setIsAddFactorOpen={setIsAddFactorOpen} isAddFactorOpen={isAddFactorOpen} status={status} setStatus={setStatus} setNotification={setNotification} setEditNotify={setEditNotify}/>
            }
            {notification &&
                <Notification  
                openNotify={notification} 
                setOpenNotify={setNotification} 
                type={'success'} 
                title={`2FA has been successfully added!`}
                text={"Your account is now more secure with two-factor authentication."} />
            }
            {editNotify &&
                <Notification  
                openNotify={editNotify} 
                setOpenNotify={setEditNotify} 
                type={'success'} 
                title={`2FA has been successfully edited!`}
                text={"Your two-factor authentication settings have been successfully updated."} />
            }
            {(subscriberNotify && subscriberCount > 0) && (
                <NotificationMessage  openNotify={subscriberNotify} setOpenNotify={setSubscriberNotify} type={'success'} title={`${(subscriberCount === 1) ? `A subscriber was successfully added!`: `${subscriberCount} subscribers were successfully added!`}`} />
            )}
            {subscriberAddedNotify && (
                    <NotificationMessage  openNotify={subscriberAddedNotify} setOpenNotify={setSubscriberAddedNotify} type={'success'} title={messages} />
                )}
        </div>
    );
}

export default TwoFactor;
